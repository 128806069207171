<template>
  <div>
    <div id="product" v-if="current_active_module">
      <!-- loader starts here  -->
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>

      <!-- ends here -->
      <v-data-table
        :headers="headers"
        v-model="selected"
        :single-select="singleSelect"
        show-select
        item-key="product_id"
        hide-default-footer
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="desserts"
        :options.sync="options"
        class="elevation-1"
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <div id="product__filter">
            <v-col cols="5">
              <v-select
                :items="sectors"
                dense
                multiple
                item-text="name"
                item-value="key"
                v-model="sector_data"
                label="Sector"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                :items="product_status"
                dense
                multiple
                item-text="value"
                item-value="key"
                v-model="product_status_data"
                label="Status"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                :items="digital_channel"
                dense
                v-model="digital_channel_data"
                label="Digital Channel"
                @change="setMediaChannelOption"
              />
            </v-col>
            <v-col cols="4" v-if="displayButtons">
              <v-btn class="mx-2" small color="primary" @click="search">
                <strong>Search</strong>
              </v-btn>
              <v-btn class="mx-2" small color="default" @click="reset">
                <strong>Reset</strong>
              </v-btn>
            </v-col>
          </div>
          <searchForm
            @data="data"
            @search="search"
            @reset="reset"
            @addCompany="addCompany"
            @removeCompany="removeCompany"
            :formType="'MOBILEUNPUBLISHED'"
            @showButtons="showButtons"
            :stateName="'MdTempProduct'"
          />
          <v-container class="grey lighten-5" id="product__container">
            <v-row no-gutters>
              <v-col cols="14">
                <CopyToClipboard :muids="selectedEndPointUrls" />
                <!-- <Tabs :channel_id="channel_id" /> -->
                <div style="float:right"><v-btn color="primary" small @click.stop = "addnewproduct()" >Add Product</v-btn></div>
                <div style="float: right">
                  <v-btn
                    color="error"
                    small
                    @click.stop="deleteproduct()"
                    v-show="checkIfOperationExistForModule('delete')"
                    >Delete</v-btn
                  >
                </div>
                <div style="float: right">
                  <v-btn
                    color="primary"
                    small
                    @click.stop="massproductupdate()"
                    v-show="checkIfOperationExistForModule('edit')"
                    >Bulk Update</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <!-- product preview column -->
        <template v-slot:item.product_id="{ item }">
          <span>{{ item.product_id }}</span>

          <div>
            <!-- <v-icon small class="mr-2" @click="editItem(item)" title="Update Temp Product">mdi-circle-edit-outline</v-icon> -->
            <v-icon
              @click="addItem(item)"
              small
              class="mr-2"
              title="Publish/Unpublish Product"
              >mdi-circle-edit-outline</v-icon
            >

            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dark
                  right
                  size="20"
                  color=""
                  v-bind="attrs"
                  v-on="on"
                  style=""
                  @click="displaypreview(item.product_id, item.product_status)"
                  >mdi-file-code
                </v-icon>
              </template>

              <div
                class="pdfcontent"
                v-if="hover_product_id == item.product_id"
              >
                <div style="background-color: #eee">
                  <v-alert v-if="preview_msg != ''" dense outlined type="error">
                    {{ preview_msg }}
                  </v-alert>
                </div>
                <div class="pa-2">
                  <v-progress-circular
                    indeterminate
                    size="22"
                    color="blue-grey"
                    v-if="preview_loaded == false"
                  ></v-progress-circular>
                </div>

                <div
                  v-if="
                    file_type.toLowerCase() == 'html' &&
                    preview_loaded == true &&
                    imagelist.length > 0
                  "
                  style="
                    display: grid;
                    max-width: 350px;
                    max-height: 100px;
                    padding: 3px 0px 3px 0px;
                    overflow: scroll;
                    background-color: #fff;
                  "
                >
                  <span
                    style="font-size: 12px; color: blue; cursor: pointer"
                    @click="open_html_popup(imagelist[0].downlaod_url)"
                    >View In Full Screen</span
                  >

                  <iframe
                    frameBorder="0"
                    scrolling="no"
                    :src="imagelist[0].downlaod_url"
                    width="300"
                    height="250"
                  ></iframe>
                  <!-- <div  v-html="imagelist[0].html_content"></div>> -->
                </div>

                <div
                  v-else-if="
                    imagelist[0].downlaod_url !== null &&
                    (file_type == 'MPEG-4' ||
                      file_type == 'mp4' ||
                      file_type == 'mpeg-4' ||
                      file_type == 'video') &&
                    preview_loaded == true &&
                    imagelist.length > 0
                  "
                >
                  <video
                    width="300"
                    height="200"
                    align="left"
                    controls
                    :src="imagelist[0].downlaod_url"
                  ></video>
                </div>
                <div
                  v-else
                  v-for="imglist in imagelist"
                  :key="imglist.productID"
                  style="border: 1px solid #3f51b5; background-color: #fff"
                >
                  <span
                    v-if="imglist.file_type != ''"
                    style="
                      position: relative;
                      font-size: 12px;
                      color: blue;
                      cursor: pointer;
                      background-color: #fff;
                    "
                    @click="manage_pdf(item.product_id, item.product_status)"
                    >View In Full Screen</span
                  >

                  <div
                    style="
                      max-width: 350px;
                      max-height: 100px;
                      padding: 3px 0px 3px 0px;
                      overflow: scroll;
                    "
                  >
                    <img
                      v-if="preview_loaded == true"
                      class="border border-dark"
                      :src="imglist.downlaod_url"
                      @click="manage_pdf(item.product_id, item.product_status)"
                      style="cursor: pointer"
                    />
                  </div>
                </div>
              </div>
            </v-menu>
          </div>
        </template>
        <template v-slot:item.company="{ item }">
          <span>{{ companyName(item.product_id) }}</span>
        </template>
        <template v-slot:item.modify_date="{ item }">
          <span>{{ formateDate(item.modify_date) }}</span>
        </template>
        <template v-slot:item.product_status="{ item }">
          <span>{{ displayStatus(item.product_status) }}</span>
        </template>
        <!-- product name -->
        <template v-slot:item.pnamelink="{ item }">
          <span>
            <a
              style="text-decoration: none"
              @click="addItem(item)"
              title="Add Product"
              >{{ item.productName }}
            </a></span
          >
        </template>
        <template v-slot:item.userpopup="{ item }">
          <span
            @click.stop="openlogmodal(item.product_id, item.muid)"
            style="color: blue; cursor: pointer"
            >{{ userName(item.product_id) }}</span
          >
        </template>
        <template v-slot:item.mchannel_sector_audience="{ item }">
          <span>{{ mchannelSectorAudience(item) }}</span>
        </template>
      </v-data-table>
      <div class="text-center pt-4 pb-4">
        <Pagination
          :isloading="isLoading"
          :startRecord="startRecord"
          :currentPage="currentPage"
          :lastPage="lastPage"
          :lastRecord="lastRecord"
          :totRecords="totRecords"
          :isCurrentPageClass="isCurrentPageClass"
          :perpage="perpage"
          :getLastPageClass="getLastPageClass"
          :totPage="totPage"
          :getPages="getPages"
          @handlePerPage="handlePerPage"
          @paginate="paginate"
          @last="last"
          @getDataByPage="getDataByPage"
          :showPerPage="showPerPage"
        />
      </div>
      <!-- dialog box-->
      <v-dialog v-model="massupdatedialog" max-width="650">
        <MassUpdate
          :selected="selected"
          @massupdatedialogStatus="massupdatedialogStatus"
        />
      </v-dialog>

      <!-- dialog box-->
      <!-- dialog box-->
      <v-dialog v-model="productadmindialog" max-width="400">
        <v-card>
          <v-card-title
            class="headline"
            style="padding: 10px; font-size: 16px !important"
          >
            Product Activity Log
          </v-card-title>
          <br />
          <v-data-table
            :headers="userheaders"
            hide-default-footer
            disable-pagination
            :items="logdata"
            class="elevation-1"
          >
            <template v-slot:item.logDate="{ item }">
              <span>{{ formateDate(item.logDate) }}</span>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="productadmindialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div id="product_div" v-else>
      <div id="product_loader"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import searchForm from "./component/searchForm.vue";
import MassUpdate from "./component/MassUpdate";
import Tabs from "./component/Tabs.vue";
import CopyToClipboard from "./component/CopyToClipboard.vue";

export default {
  name: "MobileDigitalTemp",
  components: {
    Loading,
    Pagination,
    searchForm,
    MassUpdate,
    CopyToClipboard,
    Tabs,
  },
  data() {
    return {
      channel_id: 5,
      preview_msg: "",
      file_type: "",
      digital_channel: [
        "All",
        "Online Display",
        "Search Engine Marketing",
        "Online Video",
      ],
      digital_channel_data: "All",
      massupdatedialog: false,
      preview_loaded: false,
      hover_product_id: "",
      menu: "",
      filtermuid: "",
      isLoading: true,
      fullPage: true,
      productadmindialog: false,
      logdata: [],
      isLoading: false,
      fullPage: true,
      rowsPerPageItems: [25, 50, 100],
      sortDesc: true,
      sortBy: "modify_date",
      singleSelect: false,
      selected: [],
      search_product_id: "",
      selectedcompanyid: "",
      search_product_name: "",
      selectedstateid: "",
      sort: "",
      loading: true,
      options: {},
      showPerPage: true,
      userheaders: [
        {
          text: "UserName",
          value: "userid",
          width: "40%",
          class: "",
        },
        {
          text: "Action",
          value: "ProductActivity",
          width: "40%",
          class: "",
        },
        {
          text: "Date",
          value: "logDate",
          width: "20%",
          class: "",
          sortable: false,
        },
      ],
      headers: [
        {
          text: "Product Id",
          align: "start",
          sortable: true,
          value: "product_id",
          class: "v-data-table-header",
          width: "10%",
        },
        {
          text: "Date",
          value: "modify_date",
          width: "10%",
          class: "v-data-table-header",
        },
        {
          text: "EntryID",
          value: "entry_id",
          width: "10%",
          class: "v-data-table-header",
        },
        {
          text: "Media Channel",
          value: "mChannelName",
          width: "15%",
          class: "v-data-table-header",
        },
        {
          text: "Product",
          value: "product_name",
          width: "10%",
          sortable: true,
          class: "v-data-table-header",
        },
        {
          text: "Status",
          value: "product_status",
          width: "10%",
          sortable: true,
          class: "v-data-table-header",
        },

        {
          text: "Company",
          value: "company",
          width: "10%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Sector/Audience",
          value: "mchannel_sector_audience",
          sortable: false,
          width: "10%",
          class: "v-data-table-header",
        },
        {
          text: "Last User",
          value: "userpopup",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      logmuid: "",
      data: "temp_product",
      sectors: [],
      product_status: [],
      displayButtons: true,
    };
  },
  mounted() {
    var view = this,
      raw_data_endpoint = view.$url("PRODUCT_RAW_DATA");
    view.$productrouteconst.fetchAllProductStatus(view);
    view.$store
      .dispatch("MdTempProduct/setIsSearch", true)
      .then(function () {
        return view.$store.dispatch("MdTempProduct/setMustNot", {
          key: "product_status",
          value: view.$productrouteconst.APPROVED,
        });
      })
      .then(function () {
        return view.$store.dispatch("MdTempProduct/setMediaChannels", [
          view.$productrouteconst.ONLINE_DISPLAY,
          view.$productrouteconst.SEARCH_ENGINE_MARKETING,
          view.$productrouteconst.ONLINE_VIDEO,
        ]);
      });
    view
      .$fetch({ requiresAuth: true })
      .get(raw_data_endpoint)
      .then((response) => {
        view.sectors = response.data.payload;
      })
      .catch((e) => console.log(e));
    view.product_status = view.$productrouteconst.product_status();
  },
  computed: {
    ...mapGetters({
      current_active_module: "Rawdata/getCurrentActiveModule",
    }),
    sector_data: {
      get: function () {
        return this.$store.getters["MdTempProduct/getSectors"];
      },
      set: function (arr) {
        var view = this,
          all = view.sectors.filter((e) => e.key !== "0"),
          selected = arr.filter((e) => e === "0"),
          saved = view.$store.getters["MdTempProduct/getSectors"],
          flag = true;
        if (selected.length > 0) {
          if (
            arr.filter((e) => e !== "0").length <
            saved.filter((e) => e !== "0").length
          ) {
            arr = arr.filter((e) => e !== "0");
            flag = false;
          } else {
            all.forEach(function (e) {
              arr.push(e.key);
            });
          }
        }
        if (saved.includes("0") && !arr.includes("0")) {
          if (flag) {
            arr = new Array();
          }
        }
        arr = [...new Set(arr)];
        view.$store.dispatch("MdTempProduct/setSectors", arr);
      },
    },
    product_status_data: {
      get: function () {
        return this.$store.getters["MdTempProduct/getProductStatus"];
      },
      set: function (arr) {
        var view = this,
          all = view.product_status.filter((e) => e.key !== "ALL"),
          selected = arr.filter((e) => e === "ALL"),
          saved = view.$store.getters["MdTempProduct/getProductStatus"],
          flag = true;
        if (selected.length > 0) {
          if (
            !(
              saved.filter((e) => e.key !== "ALL").length >=
              arr.filter((e) => e.key !== "ALL").length
            )
          ) {
            all.forEach(function (e) {
              arr.push(e.key);
            });
          } else {
            arr = arr.filter((e) => e !== "ALL");
            flag = false;
          }
        }
        if (saved.includes("ALL") && !arr.includes("ALL")) {
          if (flag) {
            arr = new Array();
          }
        }
        arr = [...new Set(arr)];
        this.$store.dispatch("MdTempProduct/setProductStatus", arr);
      },
    },
    desserts: {
      get: function () {
        return this.$store.getters["MdTempProduct/getDesserts"];
      },
      set: function () {},
    },
    totalDesserts: {
      get: function () {
        return this.$store.getters["MdTempProduct/getTotalDesserts"];
      },
      set: function () {},
    },
    totRecords: {
      get: function () {
        return this.$store.getters["MdTempProduct/getTotRecords"];
      },
      set: function () {},
    },
    pageCount: {
      get: function () {
        return this.$store.getters["MdTempProduct/getPageCount"];
      },
      set: function () {},
    },
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return this.lastPage;
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.$store.getters["MdTempProduct/getPage"];
    },
    lastPage: function () {
      return this.$store.getters["MdTempProduct/getLastPage"];
    },
    perpage: function () {
      return this.$store.getters["MdTempProduct/getPerPage"];
    },
    page: function () {
      return this.$store.getters["MdTempProduct/getPage"];
    },
    selectedEndPointUrls: function () {
      let endpoints = [];
      let view = this;
      if (view.selected.length > 0) {
        view.desserts.forEach(function (item) {
          view.selected.forEach((o) => {
            if (item.product_id == o.product_id) {
              let enpointURL = "";
              if ( o.product_id)
                enpointURL =
                  view.$url("ADD_PRODUCT_FORM_URLV2") +
                  o.product_id +
                  "?ec_content_type=mobiledigital&product_status="+o.product_status;
              else
                enpointURL =
                  view.$url("ADD_PRODUCT_FORM_URLV2") +
                  o.product_id +
                  "?ec_content_type=mobiledigital";
              endpoints.push({ product_id: o.product_id, url: enpointURL });
            }
          });
        });
      }
      return endpoints;
    }
  },
  methods: {

    addnewproduct(){

          var view = this
          let enpointURL =view.$url('ADD_NEW_PRODUCT')+"?channel_id="+this.channel_id
          let popup = window.open(enpointURL,"Add Product","width=900,height=700")
      },
    open_html_popup(url) {
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=800,height=800"
      );
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    deleteproduct() {
      var view = this;
      if (view.selected.length > 0) {
        view.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will remove product temporarily.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove it !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              var posted_data = {};
              var data = [];
              for (var l in this.selected) {
                let local = {};
                local["product_id"] = this.selected[l].product_id;
                local["index_name"] = this.selected[l].index_name;
                data.push(local);
              }
              posted_data["data"] = data;
              let delete_data = JSON.stringify(posted_data);
              let delelete_endpoint = view.$url("SOFT_DELETE");
              this.$fetch({ requiresAuth: true })
                .post(delelete_endpoint, posted_data)
                .then((response) => {
                  this.$swal.fire({
                    icon: "success",
                    title: "Product Deleted",
                    text: "Product has been deleted successfully",
                  });
                  view.getData();
                })
                .catch(function (response) {
                  if (response.response.status == 401) {
                    window.location.href;
                  }
                });

              view.selected = [];
              console.log(JSON.stringify(posted_data));
              // delete endpoint
            } else {
              view.selected = [];
            }
          });

        //console.log(this.selected)
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    setMediaChannelOption() {
      var view = this;
      let selected_mchannel = view.digital_channel_data;
      switch (view.digital_channel_data) {
        case "All":
          view.$store.dispatch("MdTempProduct/setMediaChannels", [
            view.$productrouteconst.ONLINE_DISPLAY,
            view.$productrouteconst.SEARCH_ENGINE_MARKETING,
            view.$productrouteconst.ONLINE_VIDEO,
          ]);
          break;
        case "Online Display":
          view.$store.dispatch("MdTempProduct/setMediaChannels", [
            view.$productrouteconst.ONLINE_DISPLAY,
          ]);
          break;
        case "Search Engine Marketing":
          view.$store.dispatch("MdTempProduct/setMediaChannels", [
            view.$productrouteconst.SEARCH_ENGINE_MARKETING,
          ]);
          break;
        case "Online Video":
          view.$store.dispatch("MdTempProduct/setMediaChannels", [
            view.$productrouteconst.ONLINE_VIDEO,
          ]);
          break;
      }
    },
    formateDate: function (d) {
      return this.$utils.formateDate(d);
    },
    manage_pdf(product_id, product_status) {
      var view = this;
      let url =
        this.$url("CLIENT_FRONTEND") +
        "mobilepreview/" +
        product_id +
        "&product_status=" +
        product_status;
      url = url.replace(/%20/g, " ");
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=800,height=800"
      );
    },
    displayStatus: function (i) {
      var view = this,
        all_status = view.$store.getters["Rawdata/getProductStatus"],
        filtered = all_status.filter((o) => o.key_id === i);
      if (Array.isArray(filtered) && filtered.length > 0) {
        return filtered[0].description;
      }
      return "";
    },
    showButtons: function (o) {
      this.displayButtons = !this.displayButtons;
    },
    massupdatedialogStatus(o) {
      var view = this;
      view.massupdatedialog = false;
      view.selected = [];
      if (o == "save") view.getData();
    },
    massproductupdate() {
      var view = this;
      if (view.selected.length > 0) {
        view.massupdatedialog = true;
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    displaypreview(product_id, status) {
      var view = this;
      this.imagelist = [];
      this.preview_msg = "";
      this.preview_loaded = false;
      this.hover_product_id = product_id;
      let manage_image =
        this.$url("CLIENT_FRONTEND") + "managepdf/" + product_id;
      this.manage_pdf_url = manage_image;
      let token = this.$utils.getAccessToken();
      let product_type = "MobileProduct";
      //this.loading = true
      let temproduct_url = this.$url("PRODUCT_PREVIEW");
      this.pdf_link =
        this.$url("DIRECT_MAIL_PDF") + "?product_id=" + product_id;
      this.$fetch({ requiresAuth: true })
        .get(
          temproduct_url +
            "?product_type=" +
            product_type +
            "&product_id=" +
            product_id +
            "&product_status=" +
            status,
          {
            //headers
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then((response) => {
          let items = response.data.payload;
          this.preview_loaded = true;

          this.imagelist = items;
          if (items.length > 0) {
            this.file_type = items[0].file_type;
          } else {
            this.file_type = "";
          }
        })
        .catch(function (error) {
          view.preview_loaded = true;

          view.preview_msg = "Image/Video does not exist for this product";
          console.log(error.response);
        });
    },
    filterdata() {
      this.getData();
    },
    reset() {
      var view = this;
      view.digital_channel_data = "All";
      view.$store.dispatch("MdTempProduct/setPage", 1);
      view.$store
        .dispatch("MdTempProduct/resetSearch")
        .then(function () {
          return view.$store.dispatch("MdTempProduct/setPerPage", 10);
        })
        .then(function () {
          return view.$store.dispatch("MdTempProduct/setIsSearch", true);
        })
        .then(function () {
          return view.$store.dispatch("MdTempProduct/setMustNot", {
            key: "product_status",
            value: view.$productrouteconst.APPROVED,
          });
        })
        .then(function () {
          return view.$store.dispatch("MdTempProduct/setMediaChannels", [
            view.$productrouteconst.ONLINE_DISPLAY,
            view.$productrouteconst.SEARCH_ENGINE_MARKETING,
            view.$productrouteconst.ONLINE_VIDEO,
          ]);
        })
        .then(function () {
          view.sortBy = "modify_date";
          view.sortDesc = true;
          view.getData();
        });
    },
    getDataByPage(n) {
      let view = this,
        paging = view.$store.getters["MdTempProduct/getPaging"],
        obj = paging.filter((e) => e.page_number === n);
      view.$store
        .dispatch("MdTempProduct/setPage", n)
        .then(function () {
          return view.$store.dispatch("MdTempProduct/setPagingObj", obj);
        })
        .then(function () {
          view.getData();
        });
    },
    handlePerPage(n) {
      let view = this;
      view.$store
        .dispatch("MdTempProduct/setPage", 1)
        .then(function () {
          return view.$store.dispatch("MdTempProduct/setPerPage", n);
        })
        .then(function () {
          view.options.itemsPerPage = n;
          view.getData();
        });
    },
    paginate(n) {
      if (
        parseInt(n) === parseInt(0) &&
        parseInt(this.currentPage) === parseInt(1)
      ) {
        return;
      }
      if (parseInt(n) === parseInt(-1)) {
        if (parseInt(this.currentPage) <= parseInt(1)) {
          return;
        }
      }
      if (parseInt(n) === parseInt(1)) {
        if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
          return;
        }
      }
      let view = this,
        paging = view.$store.getters["MdTempProduct/getPaging"],
        obj = [];
      n =
        parseInt(n) === parseInt(0)
          ? parseInt(1)
          : parseInt(n) + parseInt(view.currentPage);
      obj = paging.filter((e) => e.page_number === n);
      view.$store
        .dispatch("MdTempProduct/setPagingObj", obj)
        .then(function () {
          return view.$store.dispatch("MdTempProduct/setPage", n);
        })
        .then(function () {
          view.getData();
        });
    },
    last() {
      let view = this;
      view.$store
        .dispatch("MdTempProduct/setPage", view.lastPage)
        .then(function () {
          view.getData();
        });
    },
    openlogmodal(product_id, muid) {
      var view = this;
      view.loading = true;
      view.productadmindialog = true;
      view.isLoading = true;
      view.logdata = [];
      let logurl =
        view.$url("PRODUCT_ADMIN_LOG") + product_id + "?type=process";
      let token = view.$utils.getAccessToken();
      this.$fetch({ requiresAuth: true })
        .get(logurl, {
          //headers
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          view.isLoading = false;
          view.loading = false;
          view.logdata = response.data.payload;
          view.logmuid = muid;
        })
        .catch(function (response) {
          if (response.response.status == 401) {
            window.location.href;
            view.loading = false;
          }
        });
    },
    getData: function () {
      let view = this,
        data = {};
      view.loading = true;
      view.isLoading = true;
      view.$store.dispatch(
        "MdTempProduct/setAllowedSectors",
        view.$store.getters["Rawdata/getSectors"]
      );
      data["request"] = view.$store.getters["MdTempProduct/getRequestPayload"];
      if (view.$store.getters["MdTempProduct/getIsSearch"]) {
        data["search"] = view.$store.getters["MdTempProduct/getSearch"];
      }
      view
        .$fetch({
          requiresAuth: true,
          operation: "add",
          vueScope: view,
        })
        .post(view.$url("TEMPPRODUCT"), data)
        .then((response) => {
          let data = response.data.payload,
            lastPage = 0;
          view.desserts = data.payload;
          lastPage = Math.ceil(
            data.totalProducts / view.$store.getters["MdTempProduct/getPerPage"]
          );
          view.$store.dispatch("MdTempProduct/setDesserts", data.payload);
          view.$store.dispatch(
            "MdTempProduct/setTotalDesserts",
            data.totalProducts
          );
          view.$store.dispatch(
            "MdTempProduct/setTotRecords",
            data.totalProducts
          );
          view.$store.dispatch("MdTempProduct/setPageCount", lastPage);
          view.$store.dispatch("MdTempProduct/setExtraData", data.extra_data);
          view.$store.dispatch(
            "MdTempProduct/setTotProducts",
            data.totalProducts
          );
          view.$store.dispatch("MdTempProduct/setLastPage", lastPage);
          if ("paging" in data) {
            if (Array.isArray(data.paging)) {
              view.$store.dispatch("MdTempProduct/setPaging", data.paging);
            }
          }
          view.loading = false;
          view.isLoading = false;
          view.selected = [];
        })
        .catch(function (e) {
          view.loading = false;
          view.isLoading = false;
          console.log(e);
        });
    },
    addItem(item) {
      var view = this;
      let product_id = "";
      let product_status = item.product_status;
      product_id = item.product_id;
      if (product_id != "") {
        let enpointURL =
          view.$url("ADD_PRODUCT_FORM_URLV2") +
          product_id +
          "?ec_content_type=mobiledigital&product_status=" +
          product_status;
        window.open(enpointURL, "_blank");
      }
    },
    editItem(item) {
      var view = this;
      let muid = "";
      muid = item.product_id;
      let product_status = item.product_status;
      if (muid != "") {
        let enpointURL =
          view.$url("EDIT_PRODUCT_FORM_URL") +
          muid +
          "?ec_content_type=mobiledigital&product_status=" +
          product_status;
        window.open(enpointURL, "_blank");
      }
    },
    companyName(id) {
      let extradata = this.$store.getters["MdTempProduct/getExtraData"];
      return id in extradata
        ? "company_name" in extradata[id]
          ? this.$utils.checkVal(extradata[id]["company_name"])
          : "--"
        : "--";
    },
    mchannelSectorAudience(i) {
      let view = this,
        str = "",
        extradata = view.$store.getters["MdTempProduct/getExtraData"],
        sec_name = "",
        pid = i.product_id,
        audience = "",
        str1 = "";
      // str= str + view.$utils.checkVal(i.mChannelName) + '/'
      // sec_name = pid in extradata ? ('sector_name' in extradata[pid] ? extradata[pid]['sector_name'] : '--') : '--'
      // str = str + view.$utils.checkVal(sec_name) + '/'

      for (var i = 0; i <= extradata[pid]["sector_name"].length - 1; i++) {
        str1 = str1 + extradata[pid]["sector_name"][i] + ",";
      }
      str = str1.substring(0, str1.length - 1) + "/";

      audience =
        pid in extradata
          ? "audience" in extradata[pid]
            ? extradata[pid]["audience"]
            : "--"
          : "--";
      str = str + view.$utils.checkVal(audience);
      return str;
    },
    userName(i) {
      let extradata = this.$store.getters["MdTempProduct/getExtraData"],
        val =
          i in extradata
            ? "admin_user" in extradata[i]
              ? extradata[i]["admin_user"]
              : "--"
            : "--";
      return val; //this.$utils.checkVal(val)
    },
    search: function () {
      var view = this;
      view.$store
        .dispatch("MdTempProduct/setPage", 1)
        // .then(function () {
        //   return view.$store.dispatch("MdTempProduct/setPerPage", 10);
        // })
        .then(function () {
          return view.$store.dispatch("MdTempProduct/setIsSearch", true);
        })
        .then(function () {
          view.getData();
        });
    },
    addCompany: function (c) {
      this.$store.dispatch("MdTempProduct/addCompany", c);
    },
    removeCompany: function (c) {
      this.$store.dispatch("MdTempProduct/removeCompany", c);
    },
  },
  watch: {
    options: {
      handler(o) {
        var view = this,
          sortBy = o.sortBy,
          sortDesc = o.sortDesc;
        if (Array.isArray(sortBy) && Array.isArray(sortDesc)) {
          if (sortBy.length > 0 && sortDesc.length > 0) {
            view.$store
              .dispatch("MdTempProduct/setPage", 1)
              .then(function () {
                return view.$store.dispatch("MdTempProduct/setSorting", {
                  field: sortBy[0],
                  direction: sortDesc[0],
                });
              })
              .then(function () {
                view.getData();
              });
          }
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
@import './../../sass/product'
</style>
<style >
.previewcontainer {
  position: relative;
}

.pdfcontent {
}

.v-icon--right {
  margin-left: 0px;
}

.complist ul {
  padding-left: 0;
  list-style: none;
}

.complist li {
  list-style: none;
  float: none;
}

.complist span {
  position: relative;
  margin: 0 5px 0 5px;
  font-size: 14px;
  cursor: pointer;
  color: #00a4e4;
}

.sectorlist ul {
  padding-left: 0;
  list-style: none;
}

.sectorlist ul li {
  float: none;
}

.sectorlist ul li {
  margin: 0 5px 0 5px;
  font-size: 14px;
  cursor: pointer;
  color: #00a4e4;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 11px;
  font-weight: bold;
  color: #fff !important;
}

.mx-3 {
  overflow-x: hidden;
}

.v-menu__content {
  box-shadow: none;
}

.v-application .text-center {
  text-align: left !important;
}
</style>
